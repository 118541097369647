<template>
  <div id="change-org-list-view">
    <vs-button @click="popupActivo = true" id="assignClinicToOrg" class="mb-px"
      >Change</vs-button
    >
    <vs-popup class="change-org-modal" title="Change Organisation" :active.sync="popupActivo">
      <div v-if="showSelect">
        <vs-select
          v-validate="'required'"
          data-vv-as="visible to"
          name="organizationId"
          placeholder="Select Organization"
          autocomplete
          v-model="organizationId"
          class="w-full"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.organizationId')"
          :danger-text="errors.first('basic.organizationId')"
          data-vv-scope="basic"
          label="Organisation Name"
          :success="
            !errors.first('basic.organizationId') && organizationId != ''
          "
        >
          <vs-select-item
            v-for="(item, index) in organizations"
            :key="index"
            :value="item._id"
            :text="item.name"
          />
        </vs-select>
        <vs-button
          @click="changeOrganization"
          color="primary"
          id="confirmRequest"
          class="mt-12"
          type="border"
          >Change Org</vs-button
        >
      </div>

      <div v-else>
        <vs-alert color="warning"
          >Further organisations are not available.</vs-alert
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isEqual } from "lodash";

export default {
  name: "change-org",
  props: {
    clinicId: {
      type: String,
      default: null,
    },
    clinicData: {
      type: Object,
    },
    currentOrg: {
      type: String,
      default: null,
    },
    updater: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      popupActivo: false,
      showSelect: true,
      organizations: [],
      organizationId: "",
      oldOrgId: null
    };
  },
  methods: {
    ...mapActions("franchise", ["assignClinics", "fetchAssignClinics"]),
    ...mapActions("organization", ["fetchOrganizationAssignList", "fetchOrganizationDetails"]),
    ...mapActions("clinic", ["updateClinicDetail"]),
    async changeOrganization() {
      if (!this.clinicId) {
        this.$vs.notify({
          title: "Validation Failed",
          text: "Please select clinics to assign.",
          color: "danger",
        });
      }
      this.$vs.loading();

      const logData = {
          ...this.clinicData,
          clinicId: this.clinicId,
          updater: this.updater.firstName + " " + this.updater.lastName,
          name: this.clinicData.clinicName,
        };

      if(!isEqual(this.oldOrgId, this.organizationId)) {
          const orgOld = await this.fetchOrganizationDetails(this.oldOrgId);
          const orgNew = await this.fetchOrganizationDetails(this.organizationId);
          logData.updatedFields = {
            organizationId: {
              oldValue: await orgOld.data.data.name,
              newValue: await orgNew.data.data.name
            }
          }
      }

      let data = {
        organizationId: this.organizationId,
        ...logData
      };

      this.updateClinicDetail({ id: this.clinicId, data })
        .then((res) => {
          if (res.status === 200) {
            this.$vs.notify({
              title: "Organization Change",
              text: "Organization updated successfully.",
              color: "success",
            });
            this.$emit("refetchClinic", this.clinicId);
            this.$vs.loading.close();
            this.popupActivo = false;
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
          this.$vs.loading.close();
        });
    },
    async getOrganizationList(id) {
      this.$vs.loading();
      this.fetchOrganizationAssignList({ id: id })
        .then((res) => {
          if (res.data.data.length <= 0) this.showSelect = false;
          else {
            this.organizations = res.data.data;
            this.showSelect = true;
            if (this.currentOrg) {
              this.organizationId = this.currentOrg;
              this.oldOrgId = this.currentOrg;
            }
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error Fetching data from server. Please reload the page and try again.",
            color: "danger",
          });
          this.popupActivo = false;
          this.$vs.loading.close();
        });
      this.serverResponded = true;
      this.$vs.loading();
    },
  },
  async created() {
    await this.getOrganizationList(this.$store.state.AppActiveUser._id);
    if (this.currentOrg) {
      this.organizationId = this.currentOrg;
      this.oldOrgId = this.currentOrg;
    }
  },
};
</script>

<style>
.change-org-modal .vs-popup--header .vs-popup--title h3 {
  display: block !important;
}

.change-org-modal .vs-popup .vs-popup--content {
  padding: 1rem !important;
}
</style>